<template>
  <CFooter :fixed="false">
    <!--<div>-->
      <!--<a href="https://coreui.io" target="_blank">CoreUI</a>-->
      <!--<span class="ml-1">&copy; {{new Date().getFullYear()}} creativeLabs.</span>-->
    <!--</div>-->
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Desenvolvido por</span>
      <a href="https://versotech.com.br">Versotech - Soluções em tecnologia</a>
    </div>
  </CFooter>
</template>

<script>
  export default {
    name: 'TheFooter'
  }
</script>